import { Workspaces } from '@air/api';
import type { Workspace } from '@air/api/types';

import { SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY } from '../SysadminSetWorkspaceModal';

type BasicWorkspaceInfo = Pick<Workspace, 'id' | 'name'>;

export const addRecentlyUsedWorkspace = async (workspaceId: string) => {
  const recentlyUsedString = localStorage.getItem(SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY);
  const recentlyUsed: BasicWorkspaceInfo[] = recentlyUsedString ? JSON.parse(recentlyUsedString) : [];

  const index = recentlyUsed.findIndex((savedWorkspace) => savedWorkspace.id === workspaceId);
  if (index === -1) {
    try {
      const workspace = await Workspaces.get({ workspaceId });

      if (workspace) {
        localStorage.setItem(
          SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY,
          JSON.stringify([workspace, ...recentlyUsed]),
        );
      }
    } catch (error) {
      console.error('Error fetching workspace', error);
    }
  } else {
    localStorage.setItem(
      SYSADMIN_SET_WORKSPACE_MODAL_LOCAL_STORAGE_KEY,
      JSON.stringify([recentlyUsed[index], ...recentlyUsed.slice(0, index), ...recentlyUsed.slice(index + 1)]),
    );
  }
};
